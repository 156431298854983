import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple } from '@fortawesome/free-brands-svg-icons'
import { faMobile, faWatchFitness, faWaveform, faLayerGroup, faArrowUp19 } from '@fortawesome/pro-light-svg-icons'
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import AppStoreLinks from "../../../components/AppStoreLinks"

const DewPage = () => {
  return (
    <Layout>
      <Seo
        title="Dew for Hydrawise"
        description="A spiffy iOS client for Hyrdrawise sprinkler systems"
      />
      <div className="flex flex-col items-center rounded justify-items-center">
        <div className="shadow-2xl rounded-3xl">
          <StaticImage
            imgClassName="rounded-3xl"
            placeholder="dominantColor"
            height={128}
            width={128}
            src="../../../images/dew-1024.png"
            alt="Dew app logo" />
        </div>
      </div>
      <h1 className="mt-12 text-5xl font-bold text-center text-dew-turquoise">Dew <i className="font-normal">for</i> Hyrdrawise</h1>
      <p className="py-4 text-3xl font-medium text-center text-gray-500">A <i>spiffy</i> iOS client for Hyrdrawise sprinkler systems</p>
      <div className="flex justify-center my-4">
        <a href="https://www.producthunt.com/posts/dew-for-hydrawise?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-dew&#0045;for&#0045;hydrawise" rel="noreferrer" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338922&theme=light" alt="Dew for Hydrawise - A spiffy iOS client for Hydrawise sprinkler controllers | Product Hunt" width="250" height="54" />
        </a>
      </div>
      <div className="grid max-w-screen-lg grid-cols-1 lg:grid-cols-[1fr,1.2fr,1fr] gap-4 mx-auto my-10">
        <div className="grid grid-rows-2 gap-8 mx-4 lg:grid-rows-3 lg:mx-0 lg:pl-6 lg:pt-16">
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faApple} />
            <h2 className="mb-4 text-2xl font-bold">An iOS native experience</h2>
            <p>A completely native iOS experience to control your sprinklers. Feel at home with gestures, haptic feedback.</p>
          </div>
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faMobile} />
            <h2 className="mb-4 text-2xl font-bold">Widgets</h2>
            <p>Add widgets to your home screen to keep track of what's happening next.</p>
          </div>
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faLayerGroup} />
            <h2 className="mb-4 text-2xl font-bold">Stack run</h2>
            <p>Stack and run zones one after the other.</p>
          </div>

        </div>
        <div className="flex items-start justify-center">
          <StaticImage
            height={730}
            width={363}
            alt="Dew landing interface"
            src="../../../images/iphone-dew.png"
            placeholder="dominantColor" />
        </div>
        <div className="grid grid-rows-2 gap-8 pt-16 mx-4 lg:grid-rows-3 lg:mx-0 lg:pr-6 ">
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faWatchFitness} />
            <h2 className="mb-4 text-2xl font-bold">On your wrist</h2>
            <p>Control your sprinkler from the watch companion app, or just glance at the status of your watering schedule.</p>
          </div>
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faWaveform} />
            <h2 className="mb-4 text-2xl font-bold">Ask Siri</h2>
            <p>Just ask Siri to turn your sprinkler zones on for you.</p>
          </div>
          <div>
            <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faArrowUp19} />
            <h2 className="mb-4 text-2xl font-bold">By name or schedule</h2>
            <p>Order your zones by name or their next available run.</p>
          </div>

        </div>
      </div>

      <AppStoreLinks
        iOSStoreLink="https://apps.apple.com/us/app/dew-for-hydrawise/id1526043388?platform=iphone" />
    </Layout>
  );
};
export default DewPage
